import { Link } from "react-router-dom";
import QRCode from "react-qr-code";
import { useEffect, useState } from "react";
import "./profile.css";

const Profile = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user.userid;
    const [publicKey, setPublicKey] = useState();

    useEffect(() => {
        const getUserInfo = async () => {
            const response = await fetch(
                `${process.env.REACT_APP_BASEURL}/members/search?id=${userId}`,
                {
                    method: "get",
                    headers: {
                        Authorization: `Bearer ${user.sessionToken}`,
                    },
                }
            );

            const data = await response.json();
            // console.log("res", data);
            setPublicKey(data?.public_key);
        };
        getUserInfo();
    }, [userId, user.sessionToken]);

    return (
        <div className="pins-template">
            {publicKey && (
                <>
                    <h1 style={{ textAlign: "center" }}>Profile</h1>

                    <div style={{ textAlign: "center" }}>
                        <Link
                            to="/profile/changepassword"
                            style={{ marginTop: "1.5rem" }}>
                            Change Password
                        </Link>
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-md-4 col-lg-4 mb-4">
                                <div className="card bg-light mb-3">
                                    <div className="card-header" align="center">
                                        Email
                                    </div>
                                    <div className="card-body" align="center">
                                        <h5 className="card-title">
                                            {user.username}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-4 col-lg-4 mb-4">
                                <div className="card bg-light mb-3">
                                    <div className="card-header" align="center">
                                        Public Key (Any ETH)
                                    </div>

                                    <div
                                        className="card-body qr"
                                        align="center" style={{display: "flex", flexDirection: "column", gap: "12px"}}>
                                            <span>{publicKey}</span>
                                            <div>
                                        <QRCode value={publicKey} />
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-4 col-lg-4 mb-4">
                                <div className="card bg-light mb-3">
                                    <div className="card-header" align="center">
                                        Token Links
                                    </div>
                                    <div
                                        className="card-body qr"
                                        align="center">
                                        <a
                                            href={`https://polygonscan.com/address/${publicKey}#tokentxnsErc721`}
                                            target="_blank"
                                            rel="noreferrer">
                                            Polygon Mainnet
                                        </a>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Profile;
