// import { useState  } from "react";
// import "./custominput.scss";

const Custominput = ({ label, name, id, value, setValue, type = "text" }) => {
  const handleChange = (e) => {
    const { value } = e.target;
    setValue(value);
  };
  return (
    <div className="form-floating mb-3">
      <input
        id={id}
        type={type}
        className="form-control"
        name={name}
        value={value}
        onChange={handleChange}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default Custominput;
