import { useContext, useEffect, useState } from "react";
import CustomInput from "../customcomponent/custominput";
import { useNavigate } from "react-router";
import AlertPopup from "../Alert/alert";
import { Alertcontext, loginstatuscontext } from "../../AppContext";
import mainPicture from "../../images/signup.jpg";

function Signup({ message }) {
  const [Emailaddress, setEmailaddress] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [error, setError] = useState("");
  const [openlogin, setOpenlogin] = useState(false);
  const alertcontext = useContext(Alertcontext);
  const login = useContext(loginstatuscontext);

  const keyDownHandler = event => {

    if (event.key === 'Enter') {
      event.preventDefault();
      handlesignin();
    }
  };


  useEffect(() => {
    setOpenlogin(!login.islogin);
  }, [login.islogin]);
  const navigate = useNavigate();

  // Function to validate email
  const ValidateEmail = (email) => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      return true;
    }
    return false;
  };

  const handlesignin = () => {

    if (!Emailaddress) {
      setError("Email is required");
    } else if (!ValidateEmail(Emailaddress)) {
      setError("Invalid email address. Please enter a valid email address.");
    } else if (!password) {
      setError("Password is required");
    } else if (!confirmpassword) {
      setError("Confirm password is required");
    } else if (password.length < 5 || password.length > 20) {
      setError("Password length must be between 5 & 20");
    } else if (confirmpassword.length < 5 || confirmpassword.length > 20) {
      setError("Confirm password length must be between 5 & 20");
    } else if (password !== confirmpassword) {
      setError("Passwords don't match!");
    } else {
      fetch(process.env.REACT_APP_BASEURL + "/collectors/signup", {
        method: "post",
        body: JSON.stringify({ email: Emailaddress, password }),
        headers: { "content-type": "application/json" },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          if (data["error"]) {
            setError(data["error"]);
          } else {
            alertcontext.setAlert({
              show: true,
              variant: "primary",
              message:
                "Congratulations! Your signup was successful. Please check your email for verification.",
            });

            // setLogin(true);
            navigate("/signin");
          }
        });
    }
  };
  const handleErrorpopup = () => {
    setError(null);
  };

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [Emailaddress, password, confirmpassword, keyDownHandler]);
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="demo-content">
            <img src={mainPicture} alt="" style={{ width: "100%" }} />
          </div>
        </div>
        <div className="col-md-6">
          <div className="demo-content bg-alt"></div>
          <div style={{ maxWidth: "600px", padding: "0 2rem" }}>
            <h2>Clifty Pins for Collectors</h2>
            Introducing Clifty Pin: The future of digital collectibles, powered
            by blockchain technology (Polygon ERC-721).
            <hr />
            <br />
            {error && (
              <AlertPopup
                Message={error}
                variant="danger"
                setShow={handleErrorpopup}
              />
            )}
            {openlogin && message && (
              <AlertPopup
                Message={message}
                variant="primary"
                setShow={() => setOpenlogin(false)}
              ></AlertPopup>
            )}
            <div
              className="mx-auto"
              style={{ maxWidth: "600px", padding: "0 2rem" }}
            >
              <h3 className="login-heading mb-4">Sign up</h3>
              <form>
                <div className="form-floating mb-3">
                  <CustomInput
                    label="Email address"
                    type="email"
                    name="Email address"
                    value={Emailaddress}
                    setValue={setEmailaddress}
                    onchange={event => setEmailaddress(event.target.value)}
                    autocomplete="off"
                  />
                </div>
                <div className="form-floating mb-3">
                  <CustomInput
                    label="Password"
                    type="password"
                    name="Password"
                    value={password}
                    setValue={setPassword}
                    onchange={event => setPassword(event.target.value)}
                    autocomplete="off"
                  />
                </div>
                <div className="form-floating mb-3">
                  <CustomInput
                    label="Confirm password"
                    type="password"
                    name="confirm password"
                    value={confirmpassword}
                    setValue={setConfirmpassword}
                    onchange={event => setConfirmpassword(event.target.value)}
                    autocomplete="off"
                  />
                </div>
                <div
                  className="col-md-2 col-lg-4 mx-auto"
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginBottom: "1rem",
                    fontSize: "14px",
                  }}
                >
                  By clicking the Sign up button below, you agree to our{" "}
                  <a href="/terms">Terms of Service</a> and{" "}
                  <a href="/privacy">Privacy Policy</a>
                </div>
                <div className="d-grid">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handlesignin}
                  >
                    SIGN UP
                  </button>
                </div>
              </form>
            </div>
            <br />
            <br />
            <div className="mx-auto text-center mx-2">
              <span>
                Already a collector, please <a href="/signin">Sign In</a> here.
              </span>
            </div>
            <div>
              <br />
              <hr />
              <div className="mx-auto text-center mx-2">
                <span>
                  To distribute Digital Collectibles, Sign Up as a Member here.
                  &nbsp;
                  <a href="https://member.clifty.io/signup">Sign Up</a> here.
                </span>
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
