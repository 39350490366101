import { Link, useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    PinterestShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    EmailShareButton,
    LivejournalShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    PinterestIcon,
    TelegramIcon,
    WhatsappIcon,
    EmailIcon,
    LivejournalIcon,
} from "react-share";

import "./index.css";

import mumbaiIcon from "../../images/MUMBAI.png";
import polygonIcon from "../../images/POLYGON.png";

const contracturl = {
    MUMBAI: "https://mumbai.polygonscan.com/address/0xc08F5D82d3FE96465D5feF453F22de0225639a1e",
    POLYGON:
        "https://polygonscan.com/address/0x60109649c4A94736b11c09B921dA365322Df5877",
};

const tokenUrl = {
    MUMBAI: "https://mumbai.polygonscan.com/token/0xc08F5D82d3FE96465D5feF453F22de0225639a1e?a=",
    POLYGON:
        "https://polygonscan.com/token/0x60109649c4A94736b11c09B921dA365322Df5877?a=",
};

const defaultdetails = {
    exists: false,
    id: 0,
    tokennumber: 0,
    name: "",
    description: "",
    mintnetwork: "",
    contract: "",
    owner: 0,
    location: "",
    artifact: "",
    extra: {
        attributes: [],
    },
    timestampfrom: "",
    timestampto: "",
    email: "",
    public_key: "",
    metadata: "",
    txhash: "",
};

const Pindetails = () => {
    // const user = JSON.parse(localStorage.getItem("user"));
    const [details, setdetails] = useState(defaultdetails);
    const { code } = useParams();
    const user = useRef(null);

    useEffect(() => {
        user.current = JSON.parse(localStorage.getItem("user"));
        const initiatepindetail = async () => {
            const data = await fetchpin();
            if (data.txhash) {
                setdetails(data);
            } else {
                fetch(`${process.env.REACT_APP_BASEURL}/collectors/mint?code=${code}`, {
                    headers: {
                        Authorization: `Bearer ${user.current.sessionToken}`,
                    },
                })
                    .then((res) => res.json())
                    .then(async (data) => {
                        if (!data.error) {
                            const detail = await fetchpin();
                            setdetails(detail);
                        }
                    });
            }
        };

        const fetchpin = async () => {
            const response = await fetch(
                `${process.env.REACT_APP_BASEURL}/collectors/nft/metadata?code=${code}`,
                {
                    headers: {
                        Authorization: `Bearer ${user.current.sessionToken}`,
                    },
                }
            );
            const data = await response.json();
            return data;
        };

        initiatepindetail();
    }, [code]);

    function urlify(text) {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function (url) {
            return '<a href="' + url + '" target="_blank">' + url + '</a>';
        });
    }

    const icon = {
        MUMBAI: mumbaiIcon,
        POLYGON: polygonIcon,
    };

    return (
        <>

            <div className="pins-template">
                <Link to="/gallery" style={{ textDecoration: "none" }}> &larr; Back to Gallery</Link>
                <h2>{details.name}</h2>
                <div className="container-fluid">
                    <div className="row" align="center">
                        <div className="card bg-light mb-3">
                            <div className="card-header" align="center">
                                <img
                                    className="pin-image"
                                    src={`https://asset.clifty.io/${details.artifact.replace(
                                        "ipfs://",
                                        "ipfs/"
                                    )}?pinataGatewayToken=Jj_K5bQqbTXNlJhh6FG9tf31rZP5XPWSGRGEf01BL4S9Ck8EZKABX6nZ-PwyNHf9`}
                                    alt="NFT"
                                />
                            </div>
                            <div className="card-body" align="center">
                                <h5 className="card-title">Issued by: {details.company}</h5>
                                <p>{details.description}</p>
                                <p>
                                    <span className="fw-bold text-uppercase">
                                        Share:{" "}
                                    </span>
                                    <FacebookShareButton
                                        url={`${process.env.REACT_APP_SHAREURL}/show/${details.uid}`}
                                        quote={details.description}
                                        hashtag="#clifty">
                                        <FacebookIcon size={32} round />
                                    </FacebookShareButton>
                                    <EmailShareButton
                                        url={`${process.env.REACT_APP_SHAREURL}/show/${details.uid}`}
                                        quote={details.description}
                                        hashtag="#clifty">
                                        <EmailIcon size={32} round />
                                    </EmailShareButton>
                                    <TwitterShareButton
                                        url={`${process.env.REACT_APP_SHAREURL}/show/${details.uid}`}
                                        quote={details.description}
                                        hashtag="#clifty">
                                        <TwitterIcon size={32} round />
                                    </TwitterShareButton>
                                    <LinkedinShareButton
                                        url={`${process.env.REACT_APP_SHAREURL}/show/${details.uid}`}
                                        quote={details.description}
                                        hashtag="#clifty">
                                        <LinkedinIcon size={32} round />
                                    </LinkedinShareButton>
                                    <WhatsappShareButton
                                        url={`${process.env.REACT_APP_SHAREURL}/show/${details.uid}`}
                                        quote={details.description}
                                        hashtag="#clifty">
                                        <WhatsappIcon size={32} round />
                                    </WhatsappShareButton>
                                    <TelegramShareButton
                                        url={`${process.env.REACT_APP_SHAREURL}/show/${details.uid}`}
                                        quote={details.description}
                                        hashtag="#clifty">
                                        <TelegramIcon size={32} round />
                                    </TelegramShareButton>
                                    <PinterestShareButton
                                        media={`${process.env.REACT_APP_SHAREURL}/show/${details.uid}`}
                                        url={`${process.env.REACT_APP_SHAREURL}/show/${details.uid}`}
                                        quote={details.description}
                                        hashtag="#clifty">
                                        <PinterestIcon size={32} round />
                                    </PinterestShareButton>
                                    <LivejournalShareButton
                                        url={`${process.env.REACT_APP_SHAREURL}/show/${details.uid}`}
                                        quote={details.description}
                                        hashtag="#clifty">
                                        <LivejournalIcon size={32} round />
                                    </LivejournalShareButton>
                                </p>
                                <h5>Viewed {details.views} times</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row" align="center">
                        <div className="card bg-light mb-3">
                            <div className="card-header" align="center">
                                Properties
                            </div>
                            <div className="card-body" align="center">
                                <ul className="list-group list-group-flush">
                                    {details.extra.attributes.map(
                                        (attribute) => {
                                            return (
                                                <li className="list-group-item">
                                                    <span className="fw-bold text-uppercase">
                                                        {
                                                            attribute[
                                                            "trait_type"
                                                            ]
                                                        }
                                                        :{" "}
                                                    </span>

                                                    <span dangerouslySetInnerHTML={{ __html: urlify(attribute["value"]) }} />
                                                </li>
                                            );
                                        }
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row" align="center">
                        <div className="card bg-light">
                            <div className="card-header" align="center">
                                NFT Details
                            </div>
                            <div className="card-body" align="center">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        <span className="fw-bold text-uppercase">
                                            Network:{" "}
                                        </span>
                                        <span>{details.mintnetwork}</span>
                                        <img
                                            className="image"
                                            style={{ float: "right" }}
                                            src={icon[details.mintnetwork]}
                                            alt="NFT"
                                        />
                                    </li>
                                    <li className="list-group-item">
                                        <span className="fw-bold text-uppercase">
                                            Token Unique ID:{" "}
                                        </span>

                                        <span>{details.uid}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <p>
                                            <span className="fw-bold text-uppercase">
                                                Contract:{" "}
                                            </span>

                                            <Link
                                                to={
                                                    contracturl[
                                                    details.mintnetwork
                                                    ]
                                                }
                                                target="_blank">
                                                Explorer
                                            </Link>
                                        </p>
                                    </li>
                                    <li className="list-group-item">
                                        <span className="fw-bold text-uppercase">
                                            Token:{" "}
                                        </span>

                                        <span>{details.tokennumber}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <span className="fw-bold text-uppercase">
                                            Minted:{" "}
                                        </span>

                                        <Link
                                            to={tokenUrl[details.mintnetwork] + details.tokennumber}
                                            target="_blank">
                                            {details.txhash ? "Yes" : "No"}
                                        </Link>
                                    </li>
                                    <li className="list-group-item">
                                        <span className="fw-bold text-uppercase">
                                            QR Code:{" "}
                                        </span>

                                        <span>{details.qrid}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    );
};

export default Pindetails;
